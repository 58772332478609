<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionLarge
        v-bind:letter="'a'"
        v-bind:selected="personalDetails.will_for === 'Myself'"
        v-bind:option="'Myself'"
        v-bind:pricing="singlePrice"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-basket-single.png'"
        @selected="save('Myself')"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            Select this option if you are a single person or if you have a
            partner but wish to make a Will only for yourself.
          </div>
        </template>
      </InputOptionLarge>
      <InputOptionLarge
        v-bind:letter="'b'"
        v-bind:selected="personalDetails.will_for === 'Myself & My Partner'"
        v-bind:option="'Myself & My Partner'"
        v-bind:pricing="jointPrice"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-basket-mirror.png'"
        @selected="save('Myself & My Partner')"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            Select this option if you and your partner are each making a Will.
            Note that, details of the people named in your Will are shared with
            your partner.
          </div>
        </template>
        <template v-slot:input>
          <div class="partner-invite">
            Please provide your partners phone number and/or email address so
            that we can send them an invite to create their 'Mirror Will':
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <input
                  ref="phone"
                  @keyup.enter.stop="$refs.email.focus()"
                  v-model="phone"
                  type="text"
                  placeholder="Partners phone number"
                  class="input-standard-grey mt30"
                />
              </div>
              <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <input
                  ref="email"
                  @keyup.enter.stop="forward"
                  v-model="email"
                  type="text"
                  placeholder="Partners email address"
                  class="input-standard-grey mt30"
                />
              </div>
            </div>
          </div>
        </template>
      </InputOptionLarge>
      <CouponDropDown></CouponDropDown>
      <PartnerModal
        v-show="show.partnerModal"
        @close="show.partnerModal = false"
        @confirm="acceptPartnerModal"
      />
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        @forwardClick="next"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'
import CouponDropDown from '@/views/pages/CheckoutView/PayPage/CouponDropDown'
import { functions } from '../../../../common/mixins/functions'
import { prices } from '@/common/mixins/prices'
import PartnerModal from './Modals/PartnerModal'

export default {
  name: 'ProfileWillFor',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge,
    CouponDropDown,
    PartnerModal
  },
  mixins: [functions, prices],
  mounted() {
    this.$emit('progress', '90%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    number() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      )
        return '13'
      return '11'
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillWillFor'
    },
    forwardTo() {
      if (this.start) return '/write_my_will/username'
      return '/profile/check_and_confirm'
    },
    backTo() {
      if (this.start) return '/write_my_will/partner_gender'
      return '/profile/partner_gender'
    },
    heading() {
      return 'Who needs a Will?'
    },
    subHeading() {
      return (
        "We can either produce a single Will or two 'mirror' Wills " +
        'suitable for a couple, both are legally binding. Prices are inclusive of VAT and include ' +
        'our professional checking service and live online & telephone support.'
      )
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    checkoutBasket() {
      return this.$store.getters.checkoutBasket
    },
    isValidated() {
      if (
        this.personalDetails.will_for &&
        (this.validEmail(this.email) || !this.email)
      )
        return true
      return false
    },
    partner() {
      return this.$store.getters.partner
    },
    singlePrice() {
      if (this.start) {
        if (this.couponSinglePrice) return this.couponSinglePrice
        else return this.formatPrice(this.prices.single)
      } else {
        return '£' + this.checkoutBasket.single_price / 100
      }
    },
    jointPrice() {
      if (this.start) {
        if (this.couponJointPrice) return this.couponJointPrice
        else return this.formatPrice(this.prices.joint)
      } else {
        return '£' + this.checkoutBasket.joint_price / 100
      }
    },
    coupon() {
      return this.$store.getters.checkoutBasket.coupon
    },
    prices() {
      return this.$store.getters.prices
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.start) {
      this.$store.dispatch('savePeople')
    }
    this.save()
    next()
  },
  watch: {
    partner: {
      immediate: true,
      deep: true,
      handler(value) {
        if (this.start) {
          this.phone = this.personalDetails.partner_phone
          this.email = this.personalDetails.partner_email
        } else {
          this.phone = value.phone
          this.email = value.email
        }
      }
    }
  },
  data() {
    return {
      phone: null,
      email: null,
      show: {
        partnerModal: false
      }
    }
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          will_for: value,
          partner_phone: this.phone,
          partner_email: this.email
        })
      }
      if (!this.start) this.savePartnerDetails()
    },
    savePartnerDetails() {
      if (this.partner) {
        var partnerClone = Object.assign({}, this.partner)
        partnerClone.phone = this.phone
        partnerClone.email = this.email
        this.$store.commit('personAdd', partnerClone)
      }
    },
    next() {
      if (this.personalDetails.will_for === 'Myself & My Partner') {
        this.show.partnerModal = true
      } else {
        this.$router.push(this.forwardTo).catch((error) => {
          console.log(error)
        })
      }
    },
    acceptPartnerModal() {
      console.log('accept partner terms')
      this.show.partnerModal = false
      this.$router.push(this.forwardTo).catch((error) => {
        console.log(error)
      })
    },
    forward() {
      if (this.isValidated) this.next()
    }
  }
}
</script>

<style></style>
