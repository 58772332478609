<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <a
      class="collapseCoupon"
      href="#"
      v-on:click="toggleCoupon"
      aria-expanded="true"
      >Add a discount code</a
    >
    <div class="coupon col-lg-5 col-md-7 col-sm-9 col-xs-12 mt30 mb30 f-right">
      <transition name="slide">
        <div v-show="showCouponInput" class="input-container">
          <input
            v-on:keyup.enter="applyCode"
            v-model="code"
            class="input-standard-grey"
            placeholder="Enter code"
          />
          <button v-on:click="applyCode" class="btn btn-medium btn--primary">
            <span class="text">apply</span>
            <span class="semicircle--right"></span>
          </button>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { http } from '@/services'

export default {
  name: 'CouponDropDown',
  data() {
    return {
      showCouponInput: false,
      code: ''
    }
  },
  computed: {
    start() {
      return this.$router.history.current.name === 'WriteMyWillWillFor'
    }
  },
  methods: {
    toggleCoupon() {
      if (this.showCouponInput) this.showCouponInput = false
      else this.showCouponInput = true
    },
    applyCode() {
      if (this.start) {
        http
          .get('/wills/api/get_code', { params: { code: this.code } })
          .then((response) => {
            if (
              response.data.partner_coupon &&
              response.data.partner_personal_details
            ) {
              console.log('partner coupon')
            } else {
              this.$store.commit('checkoutBasket', {
                coupon: response.data
              })
              this.$notification.success('Code Applied')
            }
          })
          .catch((error) => {
            this.$notification.error(error.response.data)
          })
      } else {
        http
          .post('/wills/api/apply_code', { code: this.code })
          .then((response) => {
            this.$notification.success('Coupon applied')
            this.$store.commit('checkoutBasket', response.data)
            this.showCouponInput = false
          })
          .catch((error) => {
            this.$notification.error(error.response.data)
          })
      }
    }
  }
}
</script>
