<template>
  <BaseModalSmall
    @close="close"
    v-bind:isValidated="true"
    @confirm="confirm"
    :noSlide="true"
  >
    <template v-slot:header>Your partner's Will</template>
    <template v-slot:body>
      <div>
        <p class="highlight">
          Once you have created your Will<span v-if="paymentRequired">
            and made payment</span
          >,
          <span id="partner-with-email" class="is-hidden">
            <span class="partner-name-mirror"></span> will be emailed a voucher
            so that <span class="partner-he-she"></span> can create
            <span class="partner-his-her"></span> Mirror Will. You will also be
            emailed the voucher code and details.</span
          >
          <span id="partner-without-email"
            >you will be emailed a voucher with details on creating
            {{ $store.getters.personalDetails.partner_full_name }}'s Will.</span
          >
        </p>

        <h5>What are Mirror Wills?</h5>
        <p>
          Couples often make wills with 'Mirrored' wishes, that is to say a
          husband might leave his estate to his wife and vise-versa. Where the
          wishes in two wills are mirrored like this they are often referred to
          as 'Mirror Wills' or a 'Mirror Will', in practice they are two
          separate documents, signed and witnessed independently of each other.
        </p>
      </div>
    </template>
    <template v-slot:button> I Understand </template>
  </BaseModalSmall>
</template>

<script>
import BaseModalSmall from '@/common/ui/BaseModalSmall'

export default {
  name: 'PartnerModal',
  components: {
    BaseModalSmall
  },
  methods: {
    close() {
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
    }
  },
  computed: {
    coupon() {
      return this.$store.getters.checkoutBasket.coupon
    },
    paymentRequired() {
      if (this.coupon)
        return (
          !this.coupon.price_discount_percentage === 100 ||
          !this.coupon.price_fixed_single === 0
        )
      else return true
    }
  }
}
</script>

<style scoped>
.highlight {
  padding: 15px;
  background-color: #faf9fe;
  color: #2f2c2c;
}
h5 {
  font-weight: 600;
  margin-top: 35px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
p {
  margin: 0 0 0.75em;
  line-height: 1.7em;
  font-size: 18px;
}
</style>
