export const functions = {
  methods: {
    validEmail: function (email) {
      var emailTrimmed = ''
      if (email) {
        emailTrimmed = email.trim()
      }

      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(emailTrimmed)
    },
    validPhone: function (phone) {
      if (!phone) return true
      if (phone.length > 10) return true
      return false
    }
  }
}
