export const prices = {
  methods: {
    formatPrice(value) {
      var price = Number(value) / 100
      return '£' + price
    }
  },
  computed: {
    singlePrice() {
      return this.formatPrice(this.prices.single)
    },
    jointPrice() {
      return this.formatPrice(this.prices.joint)
    },
    couponSinglePrice() {
      if (this.coupon) {
        if (this.coupon.price_discount_percentage) {
          return this.formatPrice(
            this.prices.single -
              (this.prices.single / 100) *
                Number(this.coupon.price_discount_percentage)
          )
        } else if (this.coupon.price_fixed_single !== null) {
          return this.formatPrice(this.coupon.price_fixed_single)
        } else if (this.coupon.price_discount_amount !== null) {
          return this.formatPrice(
            this.prices.joint - this.coupon.price_discount_amount
          )
        }
      }
      return null
    },
    couponJointPrice() {
      if (this.coupon) {
        if (this.coupon.price_discount_percentage) {
          return this.formatPrice(
            this.prices.joint -
              (this.prices.joint / 100) *
                Number(this.coupon.price_discount_percentage)
          )
        } else if (this.coupon.price_fixed_joint) {
          return this.formatPrice(this.coupon.price_fixed_joint)
        } else if (this.coupon.price_discount_amount) {
          return this.formatPrice(
            this.prices.joint - this.coupon.price_discount_amount
          )
        }
      }

      return null
    }
  }
}
